html,
body,
div#root {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    font-family: 'Ubuntu', apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: inherit;
}

.screen__inside-size {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.contain__buttons {
    box-sizing: border-box;
    height: 55px;
}

.contain__table {
    box-sizing: border-box;
    height: calc(100% - 55px);
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 1px;
}

.tooltip {
    background: #222529;
    border-radius: 10px;
    z-index: 9999;
    font-size: 12px;
    color: #fff;
    padding: 6px 8px;
    text-align: center;
}
