.App {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.customConfirmButtonSwal {
    border: none;
    color: white;
    background-color: #4a5056;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    padding: 6px 0;
    width: 180px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.customDeleteButtonSwal {
    border: none;
    color: #fff;
    background-color: #cd3434;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    padding: 6px 0;
    width: 180px;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
}

.customDeleteButtonSwal:hover {
    background-color: #fdcfcf;
    color: #cd3434;
    transition: all 0.3s;
}

.customConfirmButtonSwal:hover {
    background-color: #eaecef;
    color: black;
}

.customCancelButtonSwal {
    margin-right: 10px;
    border: none;
    background-color: #eaecef;
    color: #4a5056;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    width: 180px;
    height: 28px;
    transition: all 0.3s;
}

.customCancelButtonSwal:hover {
    background-color: #b9c0ca;
    color: #eaecef;
    transition: all 0.3s;
}

.customIconSwal {
    background-color: #d2d3d5;
    border: none;
}

.customConfirmButtonSwalPassword {
    border: none;
    color: black;
    background-color: white;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    padding: 6px 0;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.customConfirmButtonSwalPassword:hover {
    background-color: #f7ebff;
    color: #7f40a6;
    width: 100%;
}

.copyPassword {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
}

.password {
    width: 80%;
}

.copyImage {
    width: 20%;
}

.copyImageIcon {
    height: 16px;
}

.copyImageIcon:hover {
}
